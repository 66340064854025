import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Masonry from 'react-masonry-css';
import classNames from 'classnames';
import { RadioGroup } from '@headlessui/react'; // Import the RadioGroup component

// Your people data...



const milestones = [
  // Images URLs...
  {
    id: '1',
    imageUrl:
      'https://montanaroproductions.s3.amazonaws.com/milestone1.jpg',
  },
  {
    id: '2',
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/milestone3.jpg',
  },
  {
    id: '2',
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/milestone6.jpg',
  },
  {
    id: '2',
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/milestone2.jpg',
  },
  {
    id: '2',
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/milestone5.jpg',
  },
  {
    id: '2',
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/milestone4.jpg',
  },
  
];

const portraits = [
  // Images URLs...
  {
    imageUrl:
      'https://montanaroproductions.s3.amazonaws.com/Neilson1.jpg',
  },
  {
    imageUrl:
      'https://montanaroproductions.s3.amazonaws.com/RickyLam.jpg',
  },
  {
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/StephanieMars.jpg',
  },
  {
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/Zucco1.jpg',
  },
  {
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/Zucco2.jpg',
  },
  {
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/Zucco3.jpg',
  },
  {
    imageUrl:
    'https://montanaroproductions.s3.amazonaws.com/Zucco4.jpg',
  },
  // More people
];


export default function Example() {
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('portraits');

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
  };

  const closeModal = () => {
    setSelectedImageUrl('');
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu behavior
  };

  const breakpointColumnsObj = {
    default: 4, // Number of columns at the default breakpoint (e.g., 4 columns)
    1500: 3,   // Number of columns at the breakpoint 1100px (e.g., 3 columns)
    1100: 2,   // Number of columns at the breakpoint 1100px (e.g., 3 columns)
    700: 2,    // Number of columns at the breakpoint 700px (e.g., 2 columns)
    500: 1,    // Number of columns at the breakpoint 500px (e.g., 1 column)
  };

    // Define your categories and data here
    const categories = [
      { label: 'Portraits', value: 'portraits', data: portraits },
      { label: 'Milestones', value: 'milestones', data: milestones },
      // Add more categories here if needed
    ];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

   // Determine the current category based on the selectedCategory state
   const currentCategory = categories.find((category) => category.value === selectedCategory)?.data || [];

  return (
    <>
 

     {/* Add radio buttons to switch categories */}
     <div className="flex justify-center mt-20">
        <RadioGroup value={selectedCategory} onChange={handleCategoryChange} className="grid grid-cols-2 gap-x-1 auto-rows-max rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
          <RadioGroup.Label className="sr-only">Categories</RadioGroup.Label>
          {categories.map((category) => (
            <RadioGroup.Option
              key={category.value}
              value={category.value}
              className={({ checked }) =>
                classNames(
                  checked ? 'bg-gray-600 text-white' : 'text-gray-500',
                  'cursor-pointer rounded-full px-2.5 py-1'
                )
              }
            >
              <span>{category.label}</span>
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
    

      <Transition.Root show={!!selectedImageUrl} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                  {/* Modal content */}
                  <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md text-white opacity-70 hover:opacity-90 focus:outline-none"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-10 w-10" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-0 sm:mt-0 sm:text-left">
    
                      <div className="">
                        <img
                          className="h-auto max-w-full rounded-lg"
                          src={selectedImageUrl}
                          alt=""
                          draggable="false" // Disable drag
                          onContextMenu={handleContextMenu} // Disable right-click
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      
    {/* Render your images here */}
<div className="bg-white py-12 sm:py-12">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid gap-1"
      columnClassName="my-masonry-grid_column space-y-1"
    >
      {currentCategory.map((item, index) => (
        <div key={index} className="my-masonry-grid_column">
          <img
            className="w-full h-auto max-w-full rounded-md cursor-pointer"
            src={item.imageUrl}
            alt={`Image ${index + 1}`}
            onClick={() => handleImageClick(item.imageUrl)}
            draggable="false" // Disable drag
            onContextMenu={handleContextMenu} // Disable right-click
          />
        </div>
      ))}
    </Masonry>
  </div>
</div>
    </>
  );
}
