import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Push to deploy.',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'SSL certificates.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: LockClosedIcon,
  },
  {
    name: 'Simple queues.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced security.',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Powerful API.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: Cog6ToothIcon,
  },
  {
    name: 'Database backups.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. ',
    icon: ServerIcon,
  },
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-8xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-gray-600">About us</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Your Memories, Our Artistry - Unforgettable Photography & Videography Services</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          We believe that every moment, every glance, every smile, and every milestone deserves to be cherished forever. We are a passionate photography and videography business dedicated to helping you create and preserve beautiful memories that will stand the test of time.</p>

          <p className="mt-6 text-lg leading-8 text-gray-600">
Our team of talented artists and storytellers understands the importance of capturing the essence of your special moments. Whether it's a wedding, a family gathering, a corporate event, or a personal portrait session, we bring our creativity, expertise, and state-of-the-art equipment to craft visual narratives that reflect the uniqueness of your life.</p>

<p className="mt-6 text-lg leading-8 text-gray-600">
We don't just take photographs and videos; we capture the emotions, the laughter, the love, and the candid moments that make your memories truly unforgettable. Our commitment is to create timeless pieces of art that you'll proudly share with generations to come.</p>

<p className="mt-6 text-lg leading-8 text-gray-600">
Let us be a part of your journey, helping you make beautiful memories that will warm your heart for a lifetime. Contact us to discuss your vision, and together, let's capture life's magic in every frame.
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            src="https://images.unsplash.com/photo-1544507888-56d73eb6046e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2938&q=80"
            alt="App screenshot"
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            width={2432}
            height={1442}
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
 
    </div>
  )
}
