import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Masonry from 'react-masonry-css';
import classNames from 'classnames';
import { RadioGroup } from '@headlessui/react';
import { PlayCircleIcon } from "@heroicons/react/24/solid";


const people = [
  {
    id: '1',
    imageUrl: 'https://www.youtube.com/embed/bwJgeQ0BXJ8', // YouTube video URL
    imageThumbnail: 'https://montanaroproductions.s3.amazonaws.com/manta.png', // YouTube video URL
  },
  {
    id: '1',
    imageUrl: 'https://www.youtube.com/embed/fGocmSVRXlY', // YouTube video URL
    imageThumbnail: 'https://montanaroproductions.s3.amazonaws.com/jr13.png', // YouTube video URL
  },

  // Other people objects...
];

const categories = [
  { label: 'People', value: 'people', data: people },
  // Other categories...
];

const breakpointColumnsObj = {
  default: 2,
  1100: 2,
  700: 2,
  500: 1,
};

export default function Example() {
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [selectedImageType, setSelectedImageType] = useState('image'); // Initialize with 'image'
  const [selectedCategory, setSelectedCategory] = useState('people'); // Initialize with a default category

  const handleImageClick = (imageUrl, imageType) => {
    setSelectedImageUrl(imageUrl);
    setSelectedImageType(imageType);
  };

  const closeModal = () => {
    setSelectedImageUrl('');
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu behavior
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const currentCategory =
    categories.find((category) => category.value === selectedCategory)?.data || [];

  return (
    <>
    

   {/* Modal */}
<Transition.Root show={!!selectedImageUrl} as={Fragment}>
  <Dialog
    as="div"
    className="fixed inset-0 z-10 overflow-y-auto h-screen w-screen" // Set modal to full height and width
    onClose={closeModal}
  >
    <div className="flex items-center justify-center min-h-screen px-4">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div className="bg-white max-w-7xl mx-auto w-full h-full">
          {/* Rest of the modal content */}
          {selectedImageType === 'image' ? (
            <img
              className="w-full h-auto max-w-full rounded-lg"
              src={selectedImageUrl}
              alt=""
              draggable="false"
              onContextMenu={handleContextMenu}
            />
          ) : (
            <div className="aspect-w-16 aspect-h-9">
              <iframe
                className="w-full h-full aspect-video"
                src={selectedImageUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      </Transition.Child>
    </div>
  </Dialog>
</Transition.Root>


      <div className="bg-white py-24 sm:py-32">       
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid gap-2"
            columnClassName="my-masonry-grid_column space-y-2"
          >     

{currentCategory.map((item, index) => (
  <div key={index} className="my-masonry-grid_column relative">
    {item.imageUrl.includes('youtube.com') ? (
      <div
      className="relative aspect-w-16 aspect-h-9 cursor-pointer group"
      onClick={() => handleImageClick(item.imageUrl, 'iframe')}
    >
      {/* Display an image thumbnail */}
      <img
        className="w-full h-auto max-w-full rounded-md"
        src={item.imageThumbnail}
        alt={`Image ${index + 1}`}
        draggable="false"
        onContextMenu={handleContextMenu}
      />
      <PlayCircleIcon
        className="h-20 w-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white opacity-50 group-hover:opacity-80 transition-opacity z-10"
        // The `h-[40px] w-[40px]` classes will make the icon larger on hover
      />
    </div>
    ) : (
      <div className="relative">
        {/* Image */}
        <img
          className="w-full h-auto max-w-full rounded-lg cursor-pointer"
          src={item.imageUrl}
          alt={`Image ${index + 1}`}
          onClick={() => handleImageClick(item.imageUrl, 'image')}
          draggable="false"
          onContextMenu={handleContextMenu}
        />
      </div>
    )}
  </div>
))}
          </Masonry>
        </div>
      </div>
    </>
  );
}
